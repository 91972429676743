<template>
  <module-container
    :title="$t('received.unprocessed.invoices')"
    dense
    hide-new-button
    route-name="invoices.received"
    show-toolbar
  >
    <template #route>
      <invoices-list
        :hide-headers="['status_dgi']"
        :movement-type="[]"
        base-route="invoices.received"
        filter-key="received_unprocessed"
        signed
        update-route="invoices.received.view"
      >
        <template #[`item.is_processable`]="{ item }">
          <active-icon :active="item.is_processable" with-error />
        </template>
      </invoices-list>
    </template>

    <template #filters>
      <invoice-filters filter-key="received_unprocessed" />
    </template>
  </module-container>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import InvoicesList from "@/modules/invoices/components/InvoicesList.vue";
import { EventBus } from "@/services/event-bus";
import { DataTableHeader } from "@/mixins/DataTableMixin";
import { findIndex } from "lodash";
import ActiveIcon from "@/components/common/ActiveIcon.vue";
import InvoiceFilters from "@/modules/invoices/components/InvoiceFilters.vue";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";

@Component({
  components: { InvoiceFilters, ActiveIcon, InvoicesList },
})
export default class ReceivedList extends Mixins(PaginateProviderMixin) {
  onSetHeaders(arHeaders: DataTableHeader[]) {
    const idx = findIndex(arHeaders, { value: "total_price_value" });
    if (idx >= 0) {
      arHeaders.splice(idx + 1, 0, {
        value: "is_processable",
        text: "invoice.received.isprocessable",
        sortable: false,
      });
    }
  }

  created() {
    EventBus.on("invoices.list.apply.headers", this.onSetHeaders);
  }

  beforeDestroy() {
    EventBus.off("invoices.list.apply.headers");
  }
}
</script>
